import * as React from "react";

import * as styles from "src/styles/day.module.scss";
import { Day } from "src/utils/day";

interface DayProps {
    day: Day;
}
const DayTile = ({ day }: DayProps) => {
    return (
        <div className={styles.tile} style={{ backgroundImage: `url("${day.background_image}")` }}>
            <h1 className={styles.title}>
                <span className={styles.title_text}>{day.formatted_date}</span>
            </h1>
            <img className={styles.image} src={day.image} />
            <div className={styles.text} dangerouslySetInnerHTML={{ __html: day.text }} />
        </div>
    );
}
export default DayTile;

