import * as React from "react";

import * as styles from "src/styles/loading.module.scss";

interface DayProps {
    loading_svg: string;
}
const Loading = ({ loading_svg }: DayProps) => {
    return (
        <div className={styles.loading_container}>
            <svg className={styles.loading_svg} preserveAspectRatio="xMidYMid meet" viewBox="0 0 400 400" dangerouslySetInnerHTML={{ __html: loading_svg }} />
        </div>
    );
}
export default Loading;

