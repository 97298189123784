import * as React from "react";
import CryptoJS from "crypto-js";
import { graphql, PageProps } from "gatsby";
import * as styles from "src/styles/home.module.scss";
import "src/styles/global.scss";
import { Day, test_day } from "src/utils/day";
import DayTile from "src/components/day";
import Loading from "src/components/loading";

function get_current_date(): Date {
    // for debugging
    // return new Date("2022-11-26");
    return new Date(Date.now());
}

function decrypt(cipher: string, passphrase: string): string {
    return CryptoJS.AES.decrypt(cipher, passphrase).toString(CryptoJS.enc.Utf8);
}

function get_idx(length: number): number {
    const one_day = 1000 * 60 * 60 * 24;
    const diff_time = get_current_date().getTime() - new Date("2022-03-11").getTime();
    const diff_days = Math.round(diff_time / one_day);
    return diff_days % length;
}

function verify_passphrase(data: Queries.HomeQuery, passphrase: string): void {
    try {
        const test_splash_text = data.allSplash.edges[0].node.text!;
        const decrypted_test = decrypt(test_splash_text, passphrase);
        if (decrypted_test != "This is a test!")
            window.location.reload();
    }
    catch {
        window.location.reload();
        throw "wrong password";
    }
}

function get_loading_svg(data: Queries.HomeQuery, passphrase: string): string {
    return decrypt(data.allSplash.edges[1].node.text!, passphrase);
}

function get_splash_text(data: Queries.HomeQuery, passphrase: string): string {
    const encrypted_splash_texts = data.allSplash.edges.map(edge => edge.node.text!);
    // the first two are needed for other stuff
    const encrypted_splash_text = encrypted_splash_texts[get_idx(encrypted_splash_texts.length - 2) + 2];
    return decrypt(encrypted_splash_text, passphrase)
}

function get_days(data: Queries.HomeQuery, passphrase: string): Day[] {
    let new_days: Day[] = [];
    const encrypted_days = data.allEncryptedDay.edges.map(edge => edge.node);
    // TODO: remove debugging
    // new_days.push(test_day);
    encrypted_days.forEach((encrypted_day) => {
        let date_raw = decrypt(encrypted_day.date!, passphrase);
        let date = new Date(date_raw.replaceAll("_", "-"));

        // only show dates that already passed
        if (date <= get_current_date())
            new_days.push({
                date: date,
                formatted_date: new Intl.DateTimeFormat("de-de").format(date),
                text: decrypt(encrypted_day.text!, passphrase),
                image: decrypt(encrypted_day.image!, passphrase),
                background_image: decrypt(encrypted_day.background_image!, passphrase),
            });
    });
    return new_days;
}

const Home = ({ data }: PageProps<Queries.HomeQuery>) => {
    const [days, set_days] = React.useState<Day[]>([]);
    // for debugging
    // const [status, set_status] = React.useState(1);
    const [status, set_status] = React.useState(0);
    const [loading_svg, set_loading_svg] = React.useState("");
    const [splash_text, set_splash_text] = React.useState("");
    const body_ref = React.createRef<HTMLDivElement>();
    const [passphrase, set_passphrase] = React.useState("");

    function load(): void {
        // body_ref.current?.requestFullscreen();

        // needs to be gotten before input field is removed
        verify_passphrase(data, passphrase);

        set_loading_svg(get_loading_svg(data, passphrase));
        set_status(1)
        // await status change and show off the loading screen
        window.setTimeout(() => {
            set_splash_text(get_splash_text(data, passphrase));
            set_days(get_days(data, passphrase));
            set_status(2)
        }, 2000);
    }

    return (
        <div ref={body_ref} className={styles.body}>
            <div className={styles.container}>
                {status == 0 ?
                    <div className={styles.login_container}>
                        <form className={styles.login} onSubmit={e => { load(); e.preventDefault(); }}>
                            {/* for debugging */}
                            <input value={passphrase} onChange={e => set_passphrase(e.target.value)} type="password"></input>
                            <input type="submit" value="Decrypt" />
                        </form>
                    </div>
                    : undefined}

                {status == 1 ?
                    <Loading loading_svg={loading_svg} />
                    : undefined}

                {status == 2 ?
                    <div className={styles.heading}>
                        {splash_text}
                    </div>
                    : undefined}

                {status == 2 ? days.map(day =>
                    <DayTile key={day.date.toString()} day={day} />
                ) : undefined}

                {status == 2 ?
                    <div className={styles.footer_container}>
                        <div className={styles.footer_blob} />
                        <div className={styles.footer}>
                            Made with 💖 by Your Boyfriend.
                        </div>
                    </div>
                    : undefined}

            </div>
        </div>
    );
};
export default Home;

export const query = graphql`
query Home {
  allEncryptedDay {
    edges {
      node {
        date
        text
        image
        background_image
      }
    }
  }
  allSplash(sort: {fields: idx}) {
    edges {
      node {
        text
      }
    }
  }
}
`;

